/**
 * Partnerships Tabs Controller
 * @param {string} tabPanel - class name of Partnershib Tabs Block
 */
export default class PartnershipTabbsContoller {
  constructor(tabPanel) {

    try {
      // Get all tab elements
      this.tabPanel = document.querySelector(tabPanel);
      const tabButtons = this.tabPanel ? Array.prototype.slice.call(this.tabPanel.querySelectorAll('.js-tabs__title'), 0) : null;
      const tabContent = this.tabPanel ? Array.prototype.slice.call(this.tabPanel.querySelectorAll('.tabs__content'), 0) : null;
      const radioCircle = this.tabPanel ? Array.prototype.slice.call(this.tabPanel.querySelectorAll('circle[data-target]')) : null;

      if (typeof tabButtons !== 'undefined' && tabButtons !== null && tabButtons.length > 0) {
        // Add a click event on each of tab button
        tabButtons.forEach((elem) => {
          elem.addEventListener('click', () => {
            // Desktop toggle behavior
            if (window.matchMedia('(min-width: 1024px)').matches) {
              // Remove active class from other tabs content
              tabContent.forEach((e) => {
                e.classList.remove('is-active');
              });

              // Remove active class from other tabs button
              tabButtons.forEach((elem) => {
                elem.classList.remove('is-active');
              });

              // Get the target from the clicked tab
              const targetElem = elem.dataset.target;
              const targetDesk = document.getElementById(targetElem);
              elem.classList.toggle('is-active');
              targetDesk.classList.toggle('is-active');
            } else {
              // Mobile accordian behavior
              const targetElem = elem.dataset.target;
              const target = document.getElementById(targetElem);
              const targetParent = elem.closest('.tabs__item');

              tabContent.forEach((e) => {
                const tabParent2 = e.closest('.tabs__item');
                if (tabParent2 != targetParent) {
                  tabParent2.querySelector('.tabs__title').classList.remove('is-active');
                  tabParent2.querySelector('.tabs__content').classList.remove('is-active');
                }
              });
              elem.classList.toggle('is-active');
              target.classList.toggle('is-active');
            }

            // Loops SVG partnership circle
            radioCircle.forEach((circle) => {
              const radio = circle.getAttribute('data-target');
              // Animate SVG circle if it has the matching data target value
              if (radio == elem.getAttribute('data-target')) {
                circle.classList.remove('hide');
                circle.parentNode.classList.add('is-active');
              } else {
                circle.classList.add('hide');
                circle.parentNode.classList.remove('is-active');
              }
              // Animate radios when all tabs are closed on moblie
              if (!elem.classList.contains('is-active')) {
                circle.classList.remove('hide');
              }
            });
          });
        });

        if (window.matchMedia('(min-width: 1024px)').matches) {
          // Displays Harmony content on desktop default
          const tab1 = document.getElementById('tab1');
          tab1.classList.add('is-active');
          const tabs = document.querySelectorAll('a[data-target]');

          // Animates SVG radio circle pulse on hover
          tabs.forEach((link) => {
            link.addEventListener('mouseover', (event) => {
              radioCircle.forEach((circle) => {
                if (link.getAttribute('data-target') == circle.getAttribute('data-target')) {
                  circle.classList.remove('hide');
                }
              });
            });
            // Hides pulsing animation for all non active items
            link.addEventListener('mouseout', (event) => {
              radioCircle.forEach((circle) => {
                if (link.getAttribute('data-target') == circle.getAttribute('data-target') && !link.classList.contains('is-active')) {
                  circle.classList.add('hide');
                }
              });
            });
            // TODO: Fix jumping effect on click and integrate feature for mobile
            // Toggles is-active class for Tabs desktop via SVG radio circle link
            link.addEventListener('click', () => {
              // Run pulse animate on click
              const circleSVG = link.querySelector('circle[data-target]');
              circleSVG.classList.remove('hide');
              tabButtons.forEach((elem) => {
                if (link.getAttribute('data-target') == elem.getAttribute('data-target')) {
                  elem.classList.add('is-active');
                }
              });
            });
          });
        }
      }
    } catch (e) {
      console.error('[TabPanel] Problem setting Tab Panel variables/functions', e);
    }
  }
}

